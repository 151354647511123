import { api } from './axios'
import qs from 'qs'

import BA from './modules/BA'

export const BAApi = BA

export const authApi = {
  // 获取员工菜单
  getEmployeeMenu() {
    return api.get('/employee/privilege/employee_menu')
  },
  // 登录
  login({ login_name, login_pwd } = {}) {
    return api.post(
      '/employee/employee/login',
      qs.stringify({
        login_name,
        login_pwd
      })
    )
  },
  // 获取阿里云OSS签名
  getAliOssSignature({ upload_prefix, type } = {}) {
    return api.get('/api/oss/signature', {
      params: {
        upload_prefix,
        type
      }
    })
  },
  // 获取省份列表
  getProvinceList() {
    return api.get('/common/area/province')
  },
  // 获取城市列表
  getCityList({ province_id } = {}) {
    return api.get('/common/area/city', {
      params: {
        province_id
      }
    })
  },
  // 获取区县
  getDistrictList({ city_id } = {}) {
    return api.get('/common/area/district', {
      params: {
        city_id
      }
    })
  },
  // 获取员工信息
  getEmployeeInfo() {
    return api.get('/employee/employee/info')
  }
}
