import Vue from 'vue'
import Router from 'vue-router'
import BA from './modules/BA'

const Frame = () => import('@/components/frames/frame')

const Login = () => import('@/views/common/login')
const ErrorPage = () => import('@/views/common/404')

const Index = () => import('@/views')
const ChangePassword = () => import('@/views/common/changePassword')

Vue.use(Router)

export const routerMap = [
  {
    path: '/',
    name: 'frame',
    component: Frame,
    children: [
      {
        path: '/index',
        name: 'index',
        component: Index,
        meta: {
          auth: true,
          title: '主页',
          icon: 'iconfont icon-index-on'
        }
      },
      ...BA
    ],
    redirect: '/index'
  }
]
const router = new Router({
  mode: 'hash',
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/mobile/orderManage',
      name: 'mobileOrderManage',
      component: () => import('@/views/mobile/orderManage'),
    },
    {
      path: '/mobile/detail',
      name: 'mobileDetail',
      component: () => import('@/views/mobile/detail'),
    },
    {
      path: '/mobile/ba',
      name: 'mobileBa',
      component: () => import('@/views/mobile/ba'),
    },
    {
      path: '/mobile/integralMoney',
      name: 'mobileIntegralMoney',
      component: () => import('@/views/mobile/integralMoney'),
    },
    {
      path: '/mobile/integralList',
      name: 'mobileIntegralList',
      component: () => import('@/views/mobile/integralList'),
    },
    {
      path: '*',
      name: 'error',
      component: ErrorPage
    }
  ].concat(routerMap)
})

export default router
