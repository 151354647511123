import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import zh_CN from 'vee-validate/dist/locale/zh_CN'

Validator.localize('zh_CN', zh_CN)
const config = {
  errorBagName: 'errorBags',
  fieldsBagName: 'fieldBags',
  locale: 'zh_CN'
}

const validators = {
  mobile: {
    getMessage: field => '填写内容不是个手机号',
    validate: value => /^\d{0,14}$/g.test(value)
  },
  custom: {
    getMessage: field => '填写内容限定小写字母、数字或下划线',
    validate: value => /^[a-z0-9_]+$/g.test(value)
  },
}

Object.keys(validators).forEach(item => {
  Validator.extend(item, validators[item])
})

Vue.use(VeeValidate, config)
