export const MENU_INIT = 'MENU_INIT'

export default {
  state: JSON.parse(localStorage.getItem('menu')) || {},
  getters: {
    menus: state => {
      return state.menus || {}
    }
  },
  mutations: {
    [MENU_INIT](state, menu) {
      localStorage.setItem('menu', JSON.stringify(menu))
      Object.assign(state, menu)
    }
  },
  actions: {
    [MENU_INIT]({ commit }, menu) {
      commit(MENU_INIT, menu)
    }
  }
}
