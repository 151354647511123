import { formatDate } from '@/libs/utils'

const filters = {
  filter: (value, key, props) => {
    let propList = props ? [value, ...props] : [value]
    if (key && (value || value === 0)) {
      if (value === 0) {
        return value || '-'
      }
      return filters[key].apply(this, propList)
    } else if (value === 0) { return value } else return value || '-'
  },

  formatDate: (date = '2018-08-08 00:00:00', format) => {
    return formatDate(date, format)
  },

  mobile: mobile => {
    mobile = mobile + ''
    return `${mobile.substr(0, 3)}****${mobile.substring(7)}`
  },

  week: value => {
    const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    return week[value]
  },

  thousand: value => {
    return `${value / 1000}k`
  },

  whether: value => {
    return parseInt(value) ? '是' : '否'
  },

  isAccess: value => {
    return parseInt(value) ? '允许' : '禁止'
  },

  faq_status: value => {
    return parseInt(value) === 1 ? '启用' : '禁用'
  },

  is_enable: value => {
    return parseInt(value) ? '正常' : '已停用'
  },

  gender: value => {
    switch (parseInt(value)) {
      case 0:
        return '保密'
      case 1:
        return '男'
      case 2:
        return '女'
      case 3:
        return '保密'
      default:
        break
    }
  },
  log_status: value => {
    switch (parseInt(value)) {
      case 1:
        return '已创建'
      case 2:
        return '已发送请求'
      case 3:
        return '已查看未反馈'
      case 4:
        return '已拒绝'
      case 5:
        return '已同意'
      case 6:
        return '已失效'
      default:
        break
    }
  },
  isSub: value => {
    return parseInt(value) === 1 ? '主观' : '客观'
  },
  isUseable: value => {
    return parseInt(value) === 0 ? '不可用' : '可用'
  },
  isStatus: value => {
    return parseInt(value) === 0 ? '否' : '是'
  },
  questionOpts: value => {
    switch (parseInt(value)) {
      case 0:
        return 'A'
      case 1:
        return 'B'
      case 2:
        return 'C'
      case 3:
        return 'D'
      default:
        break
    }
  },
  remarkStatus: value => {
    switch (parseInt(value)) {
      case 0:
        return '未点评'
      case 1:
        return '点评发送成功'
      case 2:
        return '点评发送失败'
      default:
        break
    }
  },
  checkStatus: value => {
    switch (Number(value)) {
      case 1:
        return '待审核'
      case 2:
        return '审核通过'
      case 3:
        return '审核作废'
      case 4:
        return '审核拒绝'
      default:
        break
    }
  },
}
export default filters
