/* eslint-disable *///Disable for process
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { fakeMouthEvent } from '@/libs/utils'
import {gLogin} from "../router";

function setInterceptors (axiosInstance) {
  // request interceptor
  axiosInstance.interceptors.request.use(req => {
    return req
  }, error => {
    console.error(error)
    return Promise.reject(error)
  })

  // response interceptor
  axiosInstance.interceptors.response.use(res => {
    if (res.data.code && res.data.code !== 0) {
      switch(res.data.code) {
        case -1:
          Vue.prototype.$message.error('登录失效，请重新登录')
          store.dispatch('USER_SIGNOUT')
          gLogin()
          break
      }
    }
    return res
  }, error => {
    console.dir(error)
    if (error.code === 'ECONNABORTED') {
      Vue.prototype.$message.error('网络错误，请检查网络连接刷新后重试')
    } else {
      Vue.prototype.$message.error(`发生了奇怪的错误，Status Code: ${error.request.status}`)
    }
    return Promise.reject(error)
  })
}

// use simulate click a href to download file
function downLoadFileRow (data, fileName) {
  let blob = new Blob([data])
  let aDom = document.createElement('a')
  aDom.href = window.URL.createObjectURL(blob)
  aDom.download = fileName
  fakeMouthEvent(aDom, 'click')
}

// create axios instance
function getAxios (settings, timeout = 10000) {
  const axiosInstance = axios.create(settings)
  axios.defaults.timeout = timeout
  setInterceptors(axiosInstance)
  return axiosInstance
}

export const api = getAxios({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : '/',
  withCredentials: false,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

export const jsonApi = getAxios({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : '/',
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const formApi = getAxios({
  timeout: 30000,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
