export const ROW_SELECT = 'ROW_SELECT'
export const ROW_CLEAR = 'ROW_CLEAR'

export default {
  state: {
    rowAction: []
  },
  mutations: {
    [ROW_SELECT](state, data) {
      state.rowAction = data.slice()
    },
    [ROW_CLEAR](state) {
      state.rowAction = []
    }
  },
  actions: {
    [ROW_SELECT]({ commit }, data) {
      commit(ROW_SELECT, data)
    },
    [ROW_CLEAR]({ commit }) {
      commit(ROW_CLEAR)
    }
  }
}
