import moment from 'moment'

export const isWeiXin = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  return /microMessenger/gi.test(ua)
}

export const isQQ = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  /* eslint eqeqeq: off */
  let isqq = ua.match(/ qq/i) == ' qq'
  /* eslint eqeqeq: off */
  if (ua.match(/mqqbrowser/i) == 'mqqbrowser') {
    isqq = false
  }
  return isqq
}

export const isIos = () => {
  return /iPad|iPhone|iPod/g.test(navigator.userAgent)
}

export const isAndroid = () => {
  return /android/gi.test(navigator.userAgent)
}

export const isTel = (phone = '') => {
  return /^1[3-9]\d{9}$/g.test(phone)
}

export const isUrl = (str = '') => {
  return /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/.test(
    str
  )
}

export function isFunction(value) {
  return {}.toString.call(value) === '[object Function]'
}

const week = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
export const formatDate = (date = moment(), format = 'YYYY-MM-DD HH:mm:ss') => {
  let today = moment()
  if (!date) date = moment()
  if (!(typeof date === 'object')) {
    let d
    try {
      d = moment(date)
    } catch (e) {
      console.error(e)
    }
    if (d.toString() === 'Invalid Date') {
      return console.error('日期格式不正确，请输入日期字符串、时间戳(毫秒)或Date对象\n', `Error Date Input: ${date}`)
    } else {
      date = d
    }
  } else {
    date = moment(date)
  }

  let map = {
    Y: date.format('YYYY'),
    M: date.format('MM'),
    D: date.format('DD'),
    h: date.format('HH'),
    m: date.format('mm'),
    s: date.format('ss'),
    w: date.format('d')
  }

  return {
    date: date._d,
    dateString: date.format(format),
    standardDayString: date.format('YYYY-MM-DD'),
    standardString: date.format('YYYY-MM-DD HH:mm:ss'),
    momentDate: date,
    dateMap: map,
    week: today.format('DDD') === date.format('DDD') ? '今天' : week[date.day()]
  }
}

export const getMinute = s => {
  let h
  h = Math.floor(s / 60)
  s = s % 60
  h += ''
  s += ''
  h = h.length === 1 ? '0' + h : h
  s = s.length === 1 ? '0' + s : s
  return h + '分' + s + '秒'
}

// 节流函数
export const throttle = (callback, threshold) => {
  if (!isFunction(callback)) return
  let last, timer
  threshold = threshold || 250
  return function () {
    const context = this
    let args = arguments
    let now = +new Date()
    if (last && now < last + threshold) {
      clearTimeout(timer)
      timer = setTimeout(function () {
        callback.apply(context, args)
      }, threshold)
    } else {
      last = now
      callback.apply(context, args)
    }
  }
}

export const inArray = (str = '', array = [], key) => {
  let length = array.length
  for (let i = 0; i < length; i++) {
    if (key) {
      if (str === array[i][key]) {
        return true
      }
    } else {
      if (str === array[i]) {
        return true
      }
    }
  }
  return false
}

// simulate mouth event
export const fakeMouthEvent = (target, eventName) => {
  const event = document.createEvent('MouseEvents')
  event.initMouseEvent(
    eventName,
    true,
    true,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  )
  target.dispatchEvent(event)
}

// 获取星期（ n为0，为本周周一）
export const getWeek = n => {
  let currentWeek = new Date()
  let day = currentWeek.getDay()
  let date = currentWeek.getDate()
  let week = day === 0 ? 7 : day
  currentWeek.setDate(date - week + 1 + n)
  return currentWeek.getTime() / 1000
}

// 正则验证格式是否为ip
export const isValidIP = ip => {
  let reg =
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
  return reg.test(ip)
}

const base64ToBlob = code => {
  let parts = code.split(';base64,')
  let contentType = parts[0].split(':')[1]
  let raw = window.atob(parts[1])
  let rawLength = raw.length
  let uInt8Array = new Uint8Array(rawLength)
  for (let i = 0; i < rawLength; i++) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: contentType })
}

export const downLoadFile = (filename, content) => {
  let aLink = document.createElement('a')
  let blob = base64ToBlob(content)
  let evt = document.createEvent('HTMLEvents')
  evt.initEvent('click', true, true)
  aLink.download = filename
  aLink.href = URL.createObjectURL(blob)
  aLink.click()
}
