import Vue from 'vue'
import router from './routerMap.js'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import store from '@/store'
import { authApi } from '@/api'

const setTitle = (route) => {
  document.title = route.meta.title ? route.meta.title : '聚禾美运营系统'
}

const doLogin = async (next) => {
  try {
    let data = (await authApi.getEmployeeInfo()).data
    if (data.code === 0) {
      let obj = Object.assign({}, data.data)
      store.dispatch('USER_SIGNIN', obj)
      let menus = (await authApi.getEmployeeMenu()).data
      store.dispatch('MENU_INIT', menus.data)
      Vue.prototype.$message.success('登录成功')
      next()
    } else {
      if (data.data && data.data.errors) {
        let errKey = Object.keys(data.data.errors)[0],
          errMsg = data.data.errors[errKey][0].err_msg
        if (errMsg === '') errMsg = data.data.errors[errKey][0].err_no
        Vue.prototype.$message.error(errMsg)
      }
      store.dispatch('USER_SIGNOUT')
      goLogin()
    }
  } catch (err) {
    console.error(err)
  }
}

// 跳转登录页面
const goLogin = async () => {
  router.replace({ path: '/login' })
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  let token = Vue.$cookies.get('token')
  if (!to.meta.noBread) {
    let menu = to.matched
    // 获取layout页面标题
    for (let i of menu) {
      if (Object.keys(i.meta).length > 0 && store.state.Menu.menus) {
        for (let k of store.state.Menu.menus) {
          if (i.meta.unique === k.unique_en_name) {
            i.meta.title = k.menu_name || i.meta.title
            break
          }
        }
      }
    }
    store.dispatch('BREAD', menu)
  }
  if (!token) {
    if(to.name !== 'login') {
      goLogin()
    }
  }
  // else {
  //   doLogin(next)
  // }
  next()
})

router.afterEach((route) => {
  setTitle(route)
  NProgress.done() // 结束Progress
})
export default router
export const gLogin = goLogin
