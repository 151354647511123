import Vue from "vue";
import App from "./App.vue";
import router from './router'
import store from './store'
import filters from './libs/filters'
import { sync } from 'vuex-router-sync-previous'
import ElementUI from 'element-ui'
import '@/assets/styles/reset.less'
import '@/assets/styles/frame.less'
import '@/assets/styles/detail.less'
import '@/assets/styles/font.less'
import 'element-ui/lib/theme-chalk/index.css'
import VueCookies from 'vue-cookies'
import './libs/validator'
import './libs/commonUse'

Vue.use(ElementUI)
Vue.use(VueCookies)

sync(store, router)

Vue.config.productionTip = false;

Object.keys(filters).forEach(item => {
  Vue.filter(item, filters[item])
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
