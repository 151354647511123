const layout = () => import('@/components/frames/layout')
const layoutSecond = () => import('@/components/frames/layout_second')

export default [
  {
    path: '/baManage',
    name: 'baManage',
    component: () => import('@/views/BA/list'),
    meta: {
      auth: true,
      title: 'BA管理',
      icon: 'el-icon-s-custom',
      unique: 'ba_manage',
      isParent: false
    }
  },
  {
    path: '/dataImport',
    name: 'dataImport',
    component: () => import('@/views/data/import'),
    meta: {
      auth: true,
      title: '数据上传',
      icon: 'el-icon-s-custom',
      unique: 'data_import',
      isParent: false
    }
  },
  {
    path: '/orderManage',
    name: 'orderManage',
    component: layout,
    meta: {
      auth: true,
      title: '订单管理',
      icon: 'el-icon-s-ticket',
      unique: 'receipt_manage',
      isParent: true
    },
    children: [
      {
        path: '/orderManage/list',
        component: () => import('@/views/orderManage/list'),
        name: 'orderList',
        meta: {
          auth: true,
          title: '订单管理',
          unique: 'receipt_manage_normal'
        }
      }, {
        path: 'orderManage/listBar',
        component: layoutSecond,
        name: 'orderManageListBar',
        meta: {
          auth: true,
          title: '订单管理(子)',
          unique: 'receipt_manage_normal',
          notMenu: true
        },
        children: [
          {
            path: '/orderManage/detail',
            name: 'orderDetail',
            component: () => import('@/views/orderManage/edit'),
            meta: {
              auth: true,
              title: '订单编辑',
              parent: '/orderManage/list',
              notMenu: true
            }
          },
          {
            path: '/orderManage/add',
            name: 'orderAdd',
            component: () => import('@/views/orderManage/add'),
            meta: {
              auth: true,
              title: '订单新建',
              parent: '/orderManage/list',
              notMenu: true
            }
          }
        ],
        redirect: '/orderManage/list'
      }, {
        path: '/orderManage/cloudList',
        name: 'orderOnlineManage',
        component: () => import('@/views/orderOnlineManage/list'),
        meta: {
          auth: true,
          title: '订单管理(云店)',
          icon: 'el-icon-set-up',
          unique: 'receipt_manage_cloud',
          isParent: true
        }
      }, {
        path: '/orderManage/cloudListBar',
        name: 'orderOnlineManageBar',
        component: layoutSecond,
        meta: {
          auth: true,
          title: '订单管理(云店)',
          unique: 'receipt_manage_cloud',
          isParent: true,
          notMenu: true
        },
        children: [
          {
            path: '/orderOnlineManage/cloudDetail',
            name: 'orderOnlineDetail',
            component: () => import('@/views/orderOnlineManage/edit'),
            meta: {
              auth: true,
              title: '订单(云店)编辑',
              parent: '/orderManage/cloudList',
              notMenu: true
            }
          },
          {
            path: '/orderOnlineManage/cloudAdd',
            name: 'orderOnlineAdd',
            component: () => import('@/views/orderOnlineManage/add'),
            meta: {
              auth: true,
              title: '订单(云店)新建',
              parent: '/orderOnlineManage/cloudList',
              notMenu: true
            }
          }
        ],
        redirect: '/orderOnlineManage/cloudList'
      },
    ]
  }, {
    path: '/employee/list',
    name: 'employeeList',
    component: () => import('@/views/employee/list'),
    meta: {
      auth: true,
      title: '员工管理',
      icon: 'el-icon-user-solid',
      unique: 'employee_manage',
      isParent: true
    }
  },
  {
    path: '/employee/add',
    name: 'employeeAdd',
    component: () => import('@/views/employee/add'),
    meta: {
      auth: true,
      title: '员工新建',
      icon: 'el-icon-set-up',
      isParent: false
    }
  },
  {
    path: '/employee/edit',
    name: 'employeeEdit',
    component: () => import('@/views/employee/edit'),
    meta: {
      auth: true,
      title: '员工编辑',
      icon: 'el-icon-set-up',
      isParent: false
    }
  },
  {
    path: '/shop/list',
    name: 'shopList',
    component: () => import('@/views/shop/list'),
    meta: {
      auth: true,
      title: '门店管理',
      icon: 'el-icon-location',
      unique: 'shop_manage',
      isParent: true
    }
  },
  {
    path: '/goods/list',
    name: 'goodsList',
    component: () => import('@/views/goods/list'),
    meta: {
      auth: true,
      title: '商品管理',
      icon: 'el-icon-s-goods',
      unique: 'goods_manage',
      isParent: true
    }
  },
  {
    path: '/integralManage',
    name: 'integralManage',
    component: layout,
    meta: {
      auth: true,
      title: '积分',
      icon: 'el-icon-s-ticket',
      unique: 'integral_manage',
      isParent: true
    },
    children: [
      {
        path: '/integralManage/list',
        component: () => import('@/views/integralManage/list'),
        name: 'integralList',
        meta: {
          auth: true,
          title: '积分明细',
          unique: 'integral_list'
        }
      }, {
        path: 'integralManage/listBar',
        component: layoutSecond,
        name: 'integralListBar',
        meta: {
          auth: true,
          title: '积分明细',
          unique: 'integral_list',
          notMenu: true
        },
        redirect: '/integralManage/list'
      }, {
        path: '/integralManage/exchange',
        name: 'integralExchange',
        component: () => import('@/views/integralManage/exchange'),
        meta: {
          auth: true,
          title: '积分返现',
          icon: 'el-icon-set-up',
          unique: 'integral_money',
          isParent: true
        }
      }, {
        path: '/integralManage/exchangeBar',
        name: 'integralExchangeBar',
        component: layoutSecond,
        meta: {
          auth: true,
          title: '积分返现',
          unique: 'integral_money',
          isParent: true,
          notMenu: true
        },
        redirect: '/integralManage/exchange'
      },
    ]
  },
  {
    path: '/learningResources',
    name: 'learningResources',
    component: layout,
    meta: {
      auth: true,
      title: '学习资料管理',
      icon: 'el-icon-receiving',
      unique: 'lm_manage',
      isParent: true
    },
    children: [
      {
        path: '/learningResources/list',
        component: () => import('@/views/learningResources/list'),
        name: 'learningResourcesList',
        meta: {
          auth: true,
          title: '学习资料管理',
          unique: 'lm_list'
        }
      }, {
        path: 'learningResources/listBar',
        component: layoutSecond,
        name: 'learningResourcesListBar',
        meta: {
          auth: true,
          title: '学习资料管理',
          unique: 'lm_list',
          notMenu: true
        },
        redirect: '/learningResources/list'
      },
    ]
  },
  {
    path: '/budget',
    name: 'budget',
    component: layout,
    meta: {
      auth: true,
      title: '预算中心',
      icon: 'el-icon-receiving',
      unique: 'budget_manage',
      isParent: true
    },
    children: [
      {
        path: '/budget/list',
        component: () => import('@/views/budget/list'),
        name: 'budgetList',
        meta: {
          auth: true,
          title: '预算基础信息',
          unique: 'budget_list'
        }
      },
      {
        path: '/budget/sm_list',
        component: () => import('@/views/budget/sm_list'),
        name: 'SMList',
        meta: {
          auth: true,
          title: '预算月度分解',
          unique: 'sm_list'
        }
      },
      {
        path: '/budget/ledger_list',
        component: () => import('@/views/budget/ledger_list'),
        name: 'LedgerList',
        meta: {
          auth: true,
          title: '预算台账',
          unique: 'ledger_list'
        }
      },
      {
        path: '/budget/cash_list',
        component: () => import('@/views/budget/cash_list'),
        name: 'CashList',
        meta: {
          auth: true,
          title: '预算台账',
          unique: 'cash_list'
        }
      },
    ]
  },
]
